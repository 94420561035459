import React from "react";
import "./HomeFloatingIcon.scss";

const HomeFloatingIcon = ({ imgSrc, onClick }) => {
  return (
    <div onClick={onClick} className="home-floating-container">
      <img
        src={imgSrc}
        alt={"home-floating-icon"}
        className="home-floating-img"
      />
    </div>
  );
};

export default HomeFloatingIcon;
